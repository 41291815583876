import React from 'react'
import blog from './assets/blog_f.svg'
import github from './assets/github_f.svg'
import ig from './assets/ig_f.svg'
import linked from './assets/linked_f.svg'
import twitter from './assets/twitter_f.svg'

const Footer = () => {
	return (
		<>
			<div data-animate='slideUp' style={{animationDuration: '0.7s'}}>
				<footer>
					<div className='cont'>
						<ul className='footerlinks'>
							<li>
								<a target='_blank' rel='noreferrer' href='https://linkedin.com/in/impoiler'>
									<img data-invertable src={linked} alt='' />
								</a>
							</li>
							<li>
								<a target='_blank' rel='noreferrer' href='https://github.com/impoiler'>
									<img data-invertable src={github} alt='' />
								</a>
							</li>
							<li>
								<a target='_blank' rel='noreferrer' href='https://blog.suresh.im'>
									<img data-invertable src={blog} alt='' />
								</a>
							</li>
							<li>
								<a target='_blank' rel='noreferrer' href='https://twitter.com/elision_ims'>
									<img data-invertable src={twitter} alt='' />
								</a>
							</li>
							<li>
								<a target='_blank' rel='noreferrer' href='https://instagram.com/elision_ims'>
									<img data-invertable src={ig} alt='' />
								</a>
							</li>
						</ul>
					</div>
				</footer>
			</div>
		</>
	)
}

export default Footer

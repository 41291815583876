import React from 'react'

const Skill = (props) => {
	const skills = props?.skills

	return (
		<>
			<div
				className='bg-w bx-s pad b-radius skill'
				data-animate='fade'
				style={{animationDuration: '0.2s'}}>
				<h2 className='my_name'>My Skills</h2>

				<ul className='skills'>
					{skills?.map((item, index) => (
						<li style={{animationDuration: `${index / 4}s`}} data-animate='fade' key={index}>
							<span>{item.name}</span>
							<img data-invertable src={item.iconUrl} alt='' />
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default Skill

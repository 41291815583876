import React from 'react'
import aero from './assets/aero.svg'
import Github from './assets/github.svg'
import Linkedin from './assets/linkedin.svg'

const Overvue = () => {
	return (
		<>
			<div className='abt'>
				<div
					data-animate='fade'
					style={{animationDuration: '0.5s'}}
					className='bg-w b-radius project pad bx-s flex-col d3-sw resume '>
					<img data-invertable src={Github} alt='' />
					<a
						target='_blank'
						rel='noreferrer'
						className='hover_link'
						href='https://github.com/impoiler'>
						<img data-invertable className='link_aero' src={aero} alt='' />
					</a>
				</div>
				<div
					data-animate='fade'
					style={{animationDuration: '0.9s'}}
					className='blog pad bg-w project b-radius flex-col bx-s d3-sw'>
					<img data-invertable src={Linkedin} alt='' />
					<a
						target='_blank'
						rel='noreferrer'
						className='hover_link'
						href='https://linkedin.com/in/impoiler'>
						<img data-invertable className='link_aero' src={aero} alt='' />
					</a>
				</div>
			</div>
		</>
	)
}

export default Overvue

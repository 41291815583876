import React, {useEffect} from 'react'
import About from './About'
import ContactCard from './ContactCard'
import Intro from './Intro'
import Overvue from './Overvue'
import Skill from './Skills'
import Whatido from './Whatido'

const Home = (props) => {
	useEffect(() => {
		document.title = 'Home | SURESH'
	})

	return (
		<main>
			<div className='cards_pskj cont'>
				<Intro title={props?.title} />
				<About />
				<Overvue />
				<Skill skills={props?.skills} />
				<ContactCard />
				<Whatido />
			</div>
		</main>
	)
}

export default Home

import React from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import me from './assets/me.png'

const Intro = (props) => {
	return (
		<>
			<div
				className='bg-w bx-s pad b-radius card'
				data-animate='fade'
				style={{animationDuration: '0.3s'}}>
				<LazyLoadImage className='my_img' src={me} effect='blur' alt='me' />

				<h2 className='my_name'>Hey I'm Suresh 👋</h2>

				<h3>{props?.title}</h3>
			</div>
		</>
	)
}

export default Intro

import React from 'react'

const Success = () => {
	return (
		<div style={{minHeight: '380px'}} className='form_success'>
			<img data-invertable src='/success.gif' alt='' />

			<h3>Thanks 🤗 I got your message. I will get back to you as soon as possible.</h3>
		</div>
	)
}

export default Success

import React from 'react'
import {useEffect} from 'react'

const Error = () => {
	useEffect(() => {
		document.title = 'Page not found'
	})

	return (
		<div className='error_page'>
			<h2>404 | Page not found</h2>
		</div>
	)
}

export default Error

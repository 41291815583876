import React from 'react'

const ContactCard = () => {
	return (
		<>
			<div
				className='b-radius bg-w bx-s contact_card pad'
				data-animate='fade'
				style={{animationDuration: '0.9s'}}>
				<div className='cntt'>
					<h2>Want to talk ? 🪶</h2>
					<h3>
						<a href='mailto:hello@suresh.im'>hello@suresh.im</a>
					</h3>
				</div>
			</div>
		</>
	)
}

export default ContactCard

import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import logo from './assets/suresh.svg'
import Error from './Error'
import Spinner from './Spinner'
import Success from './Success'
import Theme from './Theme'

import {useFormspark} from '@formspark/use-formspark'

const FormsparkID = process.env.REACT_APP_FormsparkID

const Navbar = () => {
	const [menu, setmenu] = useState(false)
	const [Showform, setShowform] = useState(false)
	const [form, setvalues] = useState({})

	const [submitted, setsubmitted] = useState(false)

	const [submit, submitting] = useFormspark({
		formId: FormsparkID,
	})
	const [scrolled, setScrolled] = useState(0)

	const handlechange = (e) => {
		setvalues({
			...form,
			[e.target.name]: e.target.value,
			from: 'PORTFOLIO',
		})
	}

	const handlesubmit = async (e) => {
		e.preventDefault()
		submit(form)
			.then((e) => {
				setsubmitted('success')
				console.log(e)
			})
			.catch(() => {
				setsubmitted('failed')
			})
	}

	useEffect(() => {
		document.addEventListener('keydown', (event) => {
			if (event.code === 'Escape') {
				setShowform(false)
			}
		})
	}, [])

	useEffect(() => {
		function handleScroll(e) {
			setScrolled(document.documentElement.scrollTop)
		}

		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<>
			<Theme />
			<div transition={{duration: 0.3}}>
				<nav className='cont'>
					<div className='logo'>
						<Link to='/'>
							<img data-invertable src={logo} alt='Suresh logo' />
						</Link>
					</div>

					<ul
						data-sticky={scrolled > 50}
						onClick={() => {
							setmenu(false)
						}}
						className={menu ? 'visible' : 'hidden'}>
						<li>
							<Link to='/'>Home</Link>
						</li>
						<li>
							<Link to='/about'>About</Link>
						</li>
						<li>
							<Link to='/work'>Work</Link>
						</li>
						<li>
							{/* <a target='_blank' rel='noreferrer' href='/resume.pdf'> */}
							<a target='_blank' rel='noreferrer' href='/resume'>
								Resume <span className='extrnl'> ↗</span>
							</a>
						</li>
						<button
							className='cnt_btn mbl'
							onClick={() => {
								setShowform(true)
							}}>
							Get in touch
						</button>
					</ul>

					<div
						onClick={() => {
							setmenu(!menu)
						}}
						className='menubtn'>
						<img data-invertable src={menu ? './assets/close.svg' : './assets/menu.svg'} alt='' />
					</div>

					<button
						onClick={() => {
							setShowform(true)
						}}
						className='cnt_btn hnsm'>
						Get in touch
					</button>
				</nav>
			</div>

			{Showform ? (
				<>
					<div className='cform'>
						<div
							className='b-radius bx-s formwrapper'
							data-animate='slideUp'
							style={{animationDuration: '0.3s'}}>
							<button
								className='crossbtn'
								onClick={() => {
									setShowform(false)
								}}>
								<img data-invertable src='/assets/close.svg' alt='' />
							</button>
							<h2>
								{submitted === 'success'
									? 'Submitted'
									: submitted === 'failed'
									? 'Failed'
									: 'Contact me'}
							</h2>

							{submitting ? (
								<Spinner />
							) : submitted === 'success' ? (
								<Success />
							) : submitted === 'failed' ? (
								<Error />
							) : (
								<form onSubmit={handlesubmit}>
									<div className='flex'>
										<input
											required
											onChange={handlechange}
											type='text'
											name='name'
											placeholder='Your name'
										/>

										<input
											required
											onChange={handlechange}
											type='email'
											name='email'
											placeholder='Your email'
										/>
									</div>
									<textarea
										required
										onChange={handlechange}
										placeholder='Your Message'
										name='message'
										id=''></textarea>
									<button type='Submit'>Submit</button>
								</form>
							)}
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

export default Navbar

import React, {useEffect} from 'react'

export default function Resume() {
	useEffect(() => {
		const tim = setTimeout(() => {
			window.location.href = './resume.pdf'
		}, 1000)

		return () => {
			clearTimeout(tim)
		}
	}, [])

	return (
		<section
			style={{
				height: `calc(100vh - 180px) `,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<h3 style={{color: `var(--black)`}}>Please Wait ....</h3>
		</section>
	)
}

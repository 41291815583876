import 'swiper/css'
import 'swiper/css/effect-cards'

import React, {useEffect} from 'react'
import {EffectCards} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const Work = (props) => {
	useEffect(() => {
		document.title = 'Work | SURESH'
	})

	return (
		<>
			<main className='cont grid2 work_pg'>
				{props?.projects?.map((item, index) => (
					<div
						key={index}
						className=' pad b-radius bx-s work_card'
						data-animate='Workfade'
						style={{animationDuration: `${index + 1 / 5}s`}}>
						<div className='proj__images'>
							<Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className='swiper'>
								{item?.projectImages?.map((item, index) => (
									<SwiperSlide key={index}>
										<LazyLoadImage src={item?.url} alt='screenshots' effect='blur' />
									</SwiperSlide>
								))}
							</Swiper>

							<h2>{item?.title}</h2>
							<p>{item?.description}</p>
						</div>
						<div className='kls'>
							<a
								onClick={(e) => {
									if (!item?.githubUrl || item?.githubUrl.length < 1) {
										e.preventDefault()
									}
								}}
								area-message='not open sourced yet'
								aria-disabled={!item?.githubUrl || item?.githubUrl.length < 1}
								target='_blank'
								rel='noopener noreferrer'
								href={item?.githubUrl}>
								<img data-invertable src='./assets/github.svg' alt='link' />
								<span>Code</span>
							</a>

							<a target='_blank' rel='noopener noreferrer' href={item?.liveUrl}>
								<img data-invertable src='./assets/link.svg' alt='link' />
								<span>Visit</span>
							</a>
						</div>
					</div>
				))}
			</main>
		</>
	)
}

export default Work

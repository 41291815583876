import {gql} from '@apollo/client'

export const GET_ALL_DATA = gql`
	{
		portfolioData {
			id
			professionalTitle
			about {
				html
			}
			me {
				image {
					url
				}
			}
			experience {
				... on Experience {
					orgName
					location
					startDate
					endDate
				}
			}
			projects {
				... on Project {
					title
					description
					liveUrl
					githubUrl
					projectImages {
						url
					}
				}
			}
			skills {
				... on Skill {
					name
					name
					iconUrl
				}
			}
		}
	}
`

import React from 'react'

const Spinner = () => {
	return (
		<div style={{minHeight: '380px'}} className='width-100 height-100 spinnerWrap flex-b-center'>
			<span className='spinner'></span>
		</div>
	)
}

export default Spinner

import React from 'react'
import s from './assets/s.svg'

const Whatido = () => {
	return (
		<>
			<div
				className='b-radius bg-w bx-s cortex min-h pad '
				data-animate='fade'
				style={{animationDuration: '1.1s'}}>
				<div className='cntt'>
					<h2>Stuff that matters</h2>
					<h3>
						<a href='https://blog.suresh.im'> Read my Blog</a>
					</h3>
				</div>

				<img data-invertable src={s} alt='' />
			</div>
		</>
	)
}

export default Whatido

import React, {useEffect} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import Overvue from '../components/Overvue'

const AboutPage = (props) => {
	useEffect(() => {
		document.title = 'About | SURESH'
	})

	return (
		<>
			<div className='abtme cont' data-animate='fade' style={{animationDuration: '0.7s'}}>
				<div className='grid2'>
					<div className='myimg bx-s pad  b-radius'>
						<LazyLoadImage
							src={props?.profile?.image?.url || '/assets/suresh.jpg'}
							effect='blur'
							alt='me'
						/>
					</div>

					<div className='my_abt bx-s pad b-radius'>
						<h2>Hey I'm Suresh Chaudhary👋</h2>
						<div dangerouslySetInnerHTML={{__html: props?.about?.html}}></div>
					</div>

					<div className='exp bx-s pad b-radius'>
						<h2>Experience</h2>

						{props?.experience?.map((item, index) => (
							<div
								key={index}
								data-animate='slideUp'
								style={{animationDuration: `${index + 1 / 2}s`}}
								className='dskjsdk'>
								<h3>
									{item?.orgName} <span>{item?.location}</span>
								</h3>
								<h4>
									{item?.startDate} - {item?.endDate}
								</h4>
							</div>
						))}
					</div>
					<Overvue />
				</div>
			</div>
		</>
	)
}

export default AboutPage

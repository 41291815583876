import React from 'react'

const Error = () => {
	return (
		<div style={{minHeight: '380px'}} className='form_failed'>
			<img data-invertable src='/close.gif' alt='' />

			<h3>Oh no ! something went wrong</h3>
		</div>
	)
}

export default Error

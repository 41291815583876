import React from 'react'
import {ApolloClient, InMemoryCache, createHttpLink, ApolloProvider} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ReactDOM from 'react-dom/client'

import App from './App'

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_HYGRAPH_API,
})

const authLink = setContext((_, {headers}) => {
	return {
		headers: {
			...headers,
			authorization: process.env.REACT_APP_TOKEN ? `Bearer ${process.env.REACT_APP_TOKEN}` : '',
		},
	}
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<ApolloProvider client={client}>
		<App />
	</ApolloProvider>
)

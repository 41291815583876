import React, {useEffect, useState} from 'react'

export default function Theme() {
	const [theme, setTheme] = useState('')

	const handleTheme = () => {
		if (window.document.documentElement.getAttribute('data-theme') === 'dark') {
			window.document.documentElement.removeAttribute('data-theme')
			localStorage?.removeItem('theme')
			setTheme('light')
		} else {
			window.document.documentElement.setAttribute('data-theme', 'dark')
			localStorage?.setItem('theme', 'dark')
			setTheme('dark')
		}
	}

	useEffect(() => {
		if (localStorage.getItem('theme') === 'dark') {
			setTheme('dark')
			window.document.documentElement.setAttribute('data-theme', 'dark')
		} else {
			setTheme('light')
		}
	}, [])

	return (
		<li
			data-area-label={`Switch to ${theme === 'light' ? 'Dark' : 'Light'} theme`}
			onClick={handleTheme}
			className='theme bx-s'>
			<svg
				width='35'
				height='35'
				viewBox='0 0 69 69'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M11.5 34.5C11.5 26.2775 15.8125 18.6875 23 14.5762C30.1875 10.465 38.8125 10.465 46 14.5762C53.1875 18.6875 57.5 26.2775 57.5 34.5H11.5Z'
					className='lamp'
				/>
				{theme === 'light' && (
					<path
						d='M31.625 54.625V46H37.375V54.625H31.625ZM14.1162 47.4375L10.0625 43.3837L15.1512 38.295L19.205 42.3487L14.1162 47.4375ZM54.8838 47.4375L49.795 42.3487L53.8487 38.295L58.9375 43.3837L54.8838 47.4375Z'
						className='spark'
					/>
				)}
			</svg>
		</li>
	)
}

import React from 'react'
import {Link} from 'react-router-dom'
import aero from './assets/aero.svg'
import blog_icon from './assets/blog.svg'
import resume from './assets/resume.svg'

const About = () => {
	return (
		<>
			<main className='abt'>
				<div
					data-animate='fade'
					style={{animationDuration: '0.5s'}}
					className='bg-w b-radius pad bx-s flex-col resume '>
					<img data-invertable src={resume} alt='About icon' />
					<div>
						<p>Read more </p>
						<h2>About me</h2>
						<Link to='/about' className='hover_link'>
							<img data-invertable src={aero} alt='' />
						</Link>
					</div>
				</div>
				<div
					data-animate='fade'
					style={{animationDuration: '0.8s'}}
					className='blog pad bg-w b-radius flex-col bx-s'>
					<img data-invertable src={blog_icon} alt='About icon' />
					<div>
						<p>Have a look at</p>
						<h2>My Projects</h2>
					</div>
					<Link to='/work' className='hover_link'>
						<img data-invertable src={aero} alt='' />
					</Link>
				</div>
			</main>
		</>
	)
}

export default About

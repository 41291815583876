import './App.css'
import './color.css'

import {useQuery} from '@apollo/client'
import React, {useState} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import Footer from './components/Footer'
import Home from './components/Home'
import Messages from './components/Messages'
import Navbar from './components/Navbar'
import {GET_ALL_DATA} from './graphql/queries'
import AboutPage from './pages/About_page'
import Error from './pages/Error'
import Work from './pages/Work'
import Resume from './pages/Resume'

const App = () => {
	const [dataComp, setdata] = useState([])
	const {loading} = useQuery(GET_ALL_DATA, {
		onCompleted: (data) => {
			setdata(data?.portfolioData[0])
		},
	})

	return loading ? (
		<section className='loading-m'>
			<img data-invertable src='/loading.svg' alt='' />
		</section>
	) : (
		<BrowserRouter>
			<Navbar />
			<Routes>
				<Route
					path='/'
					element={<Home skills={dataComp?.skills} title={dataComp?.professionalTitle} />}
				/>
				<Route
					path='/about'
					element={
						<AboutPage
							experience={dataComp?.experience}
							about={dataComp?.about}
							profile={dataComp?.me}
						/>
					}
				/>
				<Route path='/work' element={<Work projects={dataComp?.projects} />} />
				<Route path='/messages' element={<Messages />} />
				<Route path='/resume' element={<Resume />} />
				<Route path='*' element={<Error />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	)
}

export default App
